import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <>
        <section className='newletter'>
            <div className="container flexSB">
                <div className="left row">
                    <h1>Stay tuned and get the latest update</h1>
                    <span>Sign up for our daily mail</span>
                </div>
                <div className="right row">
                    <input type="text" placeholder='Enter email address' />
                    <i className='fa fa-paper-plane'></i>
                </div>
            </div>
        </section>
        <footer>
            <div className="container padding">
                <div className="box logo">
                    <h1>CONTINUUM</h1>
                    <span>SECURITY GUARD SCHOOL</span>
                    <p></p>
                    <a href="https://www.facebook.com/continuumsec"><i className='fab fa-facebook-f icon' href></i></a>
                    <a href="https://www.instagram.com/continuumsecurity/"><i className='fab fa-instagram icon'></i></a>
                    <i className='fab fa-twitter icon'></i>
                    <i className='fab fa-youtube icon'></i>
                </div>
                <div className="bxo">
                    
                </div>
                <div className="box last">
                    <h3>QUEENS LOCATION</h3>
                    <ul>
                        <li>
                            <i className='fa fa-map'></i>
                            152-32 Rockaway Blvd. Suite 201, Jamaica, NY 11434
                        </li>
                        <li>
                            <i className='fa fa-phone'></i>
                            +1(718)977-0080
                        </li>
                        <li>
                            <i className='fa fa-paper-plane'></i>
                            continuumsec@aol.com
                        </li>
                    </ul>
                </div>
<div>

</div>
                <div className="box last">
                    <h3>MANHATTAN LOCATION</h3>
                    <ul>
                        <li>
                            <i className='fa fa-map'></i>
                            44 East 132nd Street, New York NY. 10037
                        </li>
                        <li>
                            <i className='fa fa-phone'></i>
                            +1(212)901-3248
                        </li>
                        <li>
                            <i className='fa fa-paper-plane'></i>
                            continuumsec@aol.com
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
        <div className="legal">
        <p>Copyright ©{new Date().getFullYear()} Conntinuum Security Consultants Inc.</p>
        </div>
    </>
  )
}

export default Footer