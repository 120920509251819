import React from 'react'
import "./price.css"
import Back from '../commmon/back/Back'
import PriceCard from './PriceCard'
import Faq from './Faq'

const Price = () => {
  return (
    <>
    <Back title="Choose your class"/>
    <section className="price padding">
        <div className="container grid">
            <PriceCard />
        </div>
    </section>
    <Faq />
    </>
  )
}

export default Price