import React from 'react'
import Header from './components/commmon/heading/Header'
import "./App.css"
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './components/home/Home'
import About from './components/about/About'
import CoursesHome from './components/allcourses/CoursesHome'
import Team from './components/team/Team'
import Price from './components/pricing/Price'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Media from './components/media/Media'

function App() {
  return (
    <>
      <Router basename='/'>
        <Header/>
        <Switch>
          <Route path="/" exact component= {Home} />
          <Route path="/about" exact component= {About} />
          <Route path="/classes" exact component= {CoursesHome} />
          <Route path="/team" exact component= {Team} />
          <Route path="/pricing" exact component= {Price} />
          <Route path="/contact" exact component= {Contact} />
          <Route path="/media" exact component= {Media} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App