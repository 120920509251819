import React from 'react'
import AboutCard from '../about/AboutCard'
import Hprice from '../testimonial/Hprice'
import Test from '../testimonial/Test'
import HAbout from './HAbout'
import Hero from './hero/Hero'

const Home = () => {
  return (
    <>
        <Hero />
        <AboutCard />
        <HAbout />
        <Test />
        <Hprice />
    </>
  )
}

export default Home