import React from 'react'
import Title from '../../commmon/title/Title'
import "./hero.css"
import { BiChevronDownCircle } from 'react-icons/bi';

const Hero = () => {
  return (
    <>
        <section className="hero">
            <div className="container">
                <div className="row">
                    <Title subtitle='WELCOME TO CONTINUUM' title='Security Guard Training'/>
                    <ul>
                        <li><BiChevronDownCircle /> New York State 8-hour class</li>
                        <li><BiChevronDownCircle /> New York State 16-hour class</li>
                        <li><BiChevronDownCircle /> 6-hour Defensive Driving class</li>
                        <li><BiChevronDownCircle /> CPR, First Aid and AED class</li>
                    </ul>
                <div className="button">
                    <button className='primary-btn'>
                        GET STARTED NOW <i className='fa fa-long-arrow-alt-right '></i>
                    </button>
                    <button>
                        VIEW CLASSES <i className='fa fa-long-arrow-alt-right '></i>
                    </button>
                </div>
                </div>
            </div>
        </section>
        <div className="marigin"></div>
        
    </>
  )
}

export default Hero