import React from 'react'

const Head = () => {
  return (
    <>
        <section className='head'>
            <div className='container flexSB'>
                <div className='logo'>
                    <h1>CONTINUUM SECURITY CONSULTANTS INC.</h1>
                    <span>SECURITY GUARD TRAINING SCHOOL/GUARD SERVICES</span>
                </div>

                <div className="social">
                    <a href="https://www.facebook.com/continuumsec"><i className='fab fa-facebook-f icon' href></i></a>
                    <a href="https://www.instagram.com/continuumsecurity/"><i className='fab fa-instagram icon'></i></a>
                    <i className='fab fa-twitter icon'></i>
                    <i className='fab fa-youtube icon'></i>
                </div>
            </div>
        </section>
    </>
  )
}

export default Head